@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap);
:root {
  --purple: #7400b8;
  --frenchViolet: #6930c3;
  --electricPurple: #b433ff;
  --lightPurple: #c35cff;
  --tuftsBlue: #5390d9;
  --blueJeans: #4ea8de;
  --vividBlue: #48bfe3;
  --crayolaBlue: #56cfe1;
  --mediumTurquoise: #64dfdf;
  --main: #11ffd4;
  --mainTransparent: #11ffd36e;
  --turquoise: #72efdd;
  --aquamarine: #80ffdb;
  --greenCrayola: #33ffc5;
  --dark: #222222;
  --darkSecondary: #1f1f1f;
  --gray: #4a4b4b;
  --graySecondary: #acacac;
  --grayThird: #bbbbbb;
  counter-reset: section;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f1f;
  background-color: var(--darkSecondary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.numbered-heading {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0 40px;
  width: 100%;
  white-space: nowrap;
}
.numbered-heading::before {
  position: relative;
  bottom: 4px;
  counter-increment: section;
  content: "0" counter(section) ".";
  margin-right: 10px;
  color: #11ffd4;
  color: var(--main);
  font-weight: 400;
}
.numbered-heading::after {
  content: "";
  display: block;
  position: relative;
  top: -5px;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: #fff;
}

.blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

